<template>
  <div class="page-services animate__animated animate__fadeIn pt-4">
    <img
      :src="require('@/assets/banners/address-updates.png')"
      class="w-100 mb-4"
      style="display: none"
      @load="
        (e) => {
          e.target.style.display = 'block';
          hideSpinner('spinner-banner');
        }
      "
    />

    <div id="spinner-banner" class="text-center my-5">
      <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
    </div>

    <h5 class="fs-la">
      Start by selecting which organisations you need to update
      <i class="fa-solid fa-circle-info" @click="openInfoTooltip()"></i>
    </h5>
<!--
    <p v-if="this.isCanopy" class="fs-md">As a Canopy customer you are entitled to free utilities update. Please make your selection 🎁</p>

    <p v-else-if="this.referrer==='cExtended'">Your previous utilities selection will be dealt with, now please select who else you need to inform  </p>
    -->

    <!-- Services -->
    <div class="row mb-4">
      <div id="row-service" class="col-12" v-for="(item, index) in services" :key="index" style="padding: 4px 2px">
        <CheckboxBig8
          :logo="`form/big8/${item.img}`"
          :description=item.name
          :informName=item.inform
          :value=item.value
          :subdescription=item.description
          :imgOnError="'form/assets/hmrc.svg'"
          :widthLogo="'70px'"
        />
      </div>
    </div>
<!--    <div v-if="this.isCanopy" class="row mt-2 mb-3">
      <div class="col d-block text-end">
        <label class="switch mb-2" style="margin-bottom: 0.5rem">
          <input type="checkbox" @change="checkClick()" :checked="iWantAllServices" />
          <span class="slider round"></span>
        </label>

        <p class="fs-xs" v-if="iWantAllServices">Toggle to only update your utilities</p>
        <p class="fs-xs" v-if="!iWantAllServices">Toggle to add more services for £35</p>
      </div>
    </div>-->
    <div class="checkboxes mb-3">
      <div class="d-flex align-items-center mb-2">
        <p-check
          style="font-size: 22px"
          name="check"
          class="pretty p-icon p-curve p-thick p-jelly conditions"
          color="primary"
          @change="toggleTerms"
          v-model="checkValue"
        >
          <div class="state">
            <i style="margin-left: -67%" class="icon mdi mdi-check"></i>
          </div>
        </p-check>
        <p class="mb-0 fs-md c-pointer" @click="autoClickCheckbox('conditions')">
          To support with my selected services, I agree to SlothMove's
          <a href="https://slothmove.com/terms-and-conditions/" target="_blank">terms</a>
        </p>
      </div>
      <p v-if="errors.termsChecked" class="fs-md" style="color: #c64a45">
        * You must agree to our terms and conditions
      </p>
    </div>

    <div v-if="informHMRC" class="label-pages hmrc d-flex p-2 align-items-center">
      <img :src="`${urlBucket}/form/assets/hmrc.png`" width="40px" alt="" class="mx-2" />
      <p class="ms-1 fs-sm mb-0">
        We will notify HMRC within 3 days and due to demand, this may take HMRC up to 4 weeks to
        reflect in your account
      </p>
    </div>

    <div v-if="informDWP" class="label-pages hmrc d-flex p-2 align-items-center mt-3">
      <img :src="`${urlBucket}/form/assets/hmrc.png`" width="40px" class="mx-2" />
      <p class="ms-1 fs-sm mb-0">
        We will notify DWP within 3 days and due to demand, this may take DWP up to 12 weeks to
        reflect in your account
      </p>
    </div>


  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { navigateTo } from "../router/index.js";
import { isMobile } from "../utils/isMobile";
import  { setRoute }  from "../router/setRoute.js";
import CheckboxBig8 from "../components/CheckboxBig8.vue";

// @ is an alias to /src

export default {
  name: "PageServicesBig8",
  components: {CheckboxBig8},
  data() {
    return {
      services: [],
      isMobile,
      checkValue: false,
      isCanopy: false,
      errors: {
        termsChecked: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    openInfoTooltip() {
      this.$store.commit("setField", ["currentTooltip", "ActionSheetServices"]);
      const pushbar = new Pushbar({
        blur: true,
        overlay: true,
      });
      pushbar.open("action-sheet");
    },
    selectService(informName, value) {
      // console.log(informName, value);
      this.$store.commit("formStore/setField", [`${informName}`, !value]);
      this.fillServices();
    },
    currentServices() {
      return [
        { name: "Utilities", img: "utilities.png", inform: "informWaterSewerage", value: this.informWaterSewerage, description:"Let us sort your water and energy bills out" },
        { name: "Council Tax", img: "council.png", inform: "informCouncilTax", value: this.informCouncilTax, description:"we'll sort your council accounts at your new and old home" },
        { name: "NHS", img: "nhs.png", inform: "informNHS", value: this.informNHS, description:"Update your address with your current GP and the NHS" },
        { name: "DVLA", img: "dvla.png", inform: "informDVLA", value: this.informDVLA , description:"Avoid £1000s in fines and penalty points by letting us update the DVLA"},
        { name: "HMRC", img: "hmrc.png", inform: "informHMRC", value: this.informHMRC, description:"Ensure HMRC know your updated address" },
        { name: "DWP", img: "dwp.png", inform: "informDWP", value: this.informDWP , description:"Update your address with State Pension, PIP, DLA and more"},
        {
          name: "TV Licence",
          img: "tv.png",
          inform: "informTVLicense",
          value: this.informTVLicense
          , description:"Setup, update, or cancel a TV licence"
        },
        {
          name: "Electoral Roll",
          img: "electoral.png",
          inform: "informElectoralRoll",
          value: this.informElectoralRoll,
          description:"Ensure you are registered to vote and keep your credit score happy"
        }
      ];
    },
    fillServices() {
      const services = [];
      /*this.isCanopy = this.referrer.includes("anopy");*/
      this.currentServices().forEach((s) => {
        if (s.name == "Banks" && this.referrer == "TaylorRose") {
          return;
        }
        /*else if (this.isCanopy){
          if (!(s.name==="Council Tax" || s.name ==="Water")){
            if (!this.iWantAllServices){
              return;
            }
          }
        }else if (this.referrer==='cExtended'){
          if (s.name==="Council Tax" || s.name ==="Water" || s.name==="Energy"){
            return;
          }
        }*/
        services.push(s);
      });
      this.services = [...new Set(services)];
    },
    isFreeUpdate() {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      return fetch(
        `${this.baseURL}/user/utils/freeUpdate?email=${this.email}`,
        requestOptions
      ).then((response) => response.json());
    },
    updateValue(itemName, value) {
      this.$store.commit("formStore/setField", [`${itemName}`, value]);
    },
    showLabelServices() {
      return this.services.find((element) => element.value == true);
    },
    validateInputs() {
      if (!this.termsChecked) {
        this.errors.termsChecked = true;
      } else {
        this.errors.termsChecked = false;
      }
    },
    toggleTerms() {
      this.updateValue("termsChecked", !this.termsChecked);
      this.fillServices();
    },
    autoClickCheckbox(className) {
      document.querySelector(`.${className} input[type=checkbox]`).click();
    },

    checkClick() {
      this.$store.commit("formStore/setField", [`iWantAllServices`, !this.iWantAllServices]);
      if (!this.iWantAllServices){
        const nodeList= document.querySelectorAll('.selected');
        for (let i=0;i<nodeList.length;i++){
          nodeList[i].click();
        }
      }
      this.fillServices();
    },

  },
  mounted() {

    this.fillServices(); //We show the boxes with the services that the user wishes to update

    this.$store.commit("setField", ["titleNavbar", "Address Updates"]);
    this.$store.commit("setField", ["step2", true]);

    this.checkValue = this.termsChecked;

    this.isFreeUpdate()
      .then((result) => {
        this.$store.commit("formStore/setField", ["freeUpdate", result]);
      })
      .catch((error) => console.log("isFreeUpdate->error", error));

    /* Taylor Rose */

    const taylorRose = this.$route.query.tr;
    if (taylorRose !== null && taylorRose !== undefined) {
      fetch(
        this.baseURL + "/encrypted/getTr?s=" + taylorRose,
        //'http://localhost:8090/encrypted/getTr?s=' + taylorRose,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          this.getGPS(this.moveFromPostCode, this.moveToPostCode);
        })
        .catch((error) => {
          // this.$store.commit("formStore/log", ["TaylorRose", taylorRose]);
        });
    } else {
      // let oldPostcode =
      //   this.$route.query.smfPrevPostcode === undefined
      //     ? this.moveFromPostCode
      //     : this.$route.query.smfPrevPostcode;
      // let newPostcode =
      //   this.$route.query.smfNewPostcode === undefined
      //     ? this.moveToPostCode
      //     : this.$route.query.smfNewPostcode;
      // if (oldPostcode && newPostcode) {
      //   this.getGPS(oldPostcode, newPostcode);
      // }
    }

    const validatePage = () => {
      this.validateInputs();

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));

      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "email",
      "moveToPostCode",
      "moveFromPostCode",
      "baseURL",
      "urlBucket",
      "informLoyalty",
      "informStudentFinance",
      "informNHS",
      "informDVLA",
      "informBanks",
      "informElectoralRoll",
      "informHMRC",
      "informMobile",
      "informDentist",
      "informBonds",
      "informGyms",
      "informDWP",
      "informPension",
      "informCharities",
      "informLottery",
      "informEnergy",
      "informWaterSewerage",
      "informCouncilTax",
      "informTVLicense",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informOpticians",
      "termsChecked",
      "iWantAllServices"

    ]),
    ...mapGetters("formStore", ["state"]),

  },
  watch: {
    informWaterSewerage(newVal) {
      if (newVal == false) {
        this.updateValue(`informNewWater`, false);
        this.updateValue(`informOldWater`, false);
      }
    },
    informCouncilTax(newVal) {
      if (newVal == false) {
        this.updateValue(`informOldCouncil`, false);
        this.updateValue(`informNewCouncil`, false);
      }
    },
    informEnergy(newVal) {
      if (!newVal) {
        this.updateValue("selectedEnergySupplier", []);
        this.updateValue("selectedGasSupplier", []);
      }
    },
    informBanks(newVal) {
      if (!newVal) {
        this.$store.commit("formStore/setField", ["selectedBanks", []]);
      }
    },
    informPension(newVal) {
      if (!newVal) {
        this.$store.commit("formStore/setField", ["pensionSelection", []]);
      }
    },
    informMobile(newVal) {
      if (!newVal) {
        this.$store.commit("formStore/setField", ["mobileSelection", []]);
      }
    },
    informGyms(newVal) {
      if (!newVal) {
        this.$store.commit("formStore/setField", ["selectedGyms", []]);
      }
    },
    informCharities(newVal) {
      if (!newVal) {
        this.$store.commit("formStore/setField", ["selectedCharities", []]);
      }
    },
    informLoyalty(newVal) {
      if (!newVal) {
        this.$store.commit("formStore/setField", ["selectedLoyalties", []]);
      }
    },
  },
};
</script>
<style lang="scss">
.beta{
  background-color: red;
  color: whitesmoke;
  border-radius: 15%;
  padding: 3%;
  margin-right: -7px;
  margin-top: -40px;
}
.fs-beta{
  font-size: 6px;
}
.page-services {
  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;
    transition: all 0.1s ease-in-out;

    img {
      width: 60%;
    }
  }

  .selected {
    background-color: #1cb0f6;
    color: white !important;
  }

  @media screen and (min-width: 1300px) and (max-width: 1980px) {
    .beta{
      background-color: red;
      color: whitesmoke;
      border-radius: 15%;
      padding: 3%;
      margin-top: -70px;
    }
    .fs-beta{
      font-size: 11px;
    }
    .label-pages.hmrc {
      p {
        font-size: 16px;
      }
    }
  }
}
</style>
